import React, { forwardRef } from "react";
import PropTypes from "prop-types";
// form
import { Controller, useFormContext } from "react-hook-form";
// react-flatpickr components
import Flatpickr from "react-flatpickr";
// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
// Soft UI Dashboard PRO React components
import SoftInput from "../SoftInput";
import SoftBox from "../SoftBox";

import SoftTypography from "../SoftTypography";

// eslint-disable-next-line react/display-name
const DatePickerField = forwardRef(
  ({ options, input, name, label, error, message }, ref) => {
    const { control } = useFormContext();

    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        height="100%"
        mb={1.5}
        ref={ref}
      >
        {label && (
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {label}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox mb={1} ml={0.5} lineHeight={0}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Flatpickr
                {...field}
                options={options}
                onChange={(selectedDates) => {
                  field.onChange(selectedDates[0]);
                }}
                /* eslint-disable-next-line no-unused-vars */
                render={({ defaultValue }, ref) => (
                  <SoftInput {...input} defaultValue={null} inputRef={ref} />
                )}
              />
            )}
          />
        </SoftBox>
        {error && message && (
          <SoftBox mt={0.75}>
            <SoftTypography component="div" variant="caption" color="error">
              {message}
            </SoftTypography>
          </SoftBox>
        )}
      </SoftBox>
    );
  }
);

// Setting default values for the props of SoftInput
DatePickerField.defaultProps = {
  label: "",
  message: "",
  error: false,
  input: {},
  options: {},
};

// Typechecking props for the SoftInput
DatePickerField.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  input: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DatePickerField;
