import { Divider, Grid, Box } from "@mui/material";
import React from "react";

import PropTypes from "prop-types";
import { TextInputField } from "../../components/FormField";

const BrandForm = () => {
  return (
    <Box>
      <Grid container mt={1}>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Name" name="name" />
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Signature" name="kashaSignature" />
        </Grid>
      </Grid>
      <Grid container mt={1}>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Api Key" name="kashaApiKey" />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default BrandForm;

BrandForm.propTypes = {
  signKey: PropTypes.string,
};
