import _ from "lodash";

const TOKEN = "TOKEN";
const API_KEYS = "API_KEYS";

export const getToken = () => localStorage.getItem(TOKEN);
export const setToken = (token) => localStorage.setItem(TOKEN, token);
export const removeToken = () => localStorage.removeItem(TOKEN);

export const setApiKeys = ({ prefix, apiKey }) => {
  const apiKeys = getApiKeys();
  apiKeys.push({ prefix, apiKey });
  return localStorage.setItem(API_KEYS, JSON.stringify(apiKeys));
};
export const getApiKeys = () =>
  JSON.parse(localStorage.getItem(API_KEYS) || "[]");
export const removeApiKey = (prefix) => {
  const apiKeys = _.filter(getApiKeys(), (item) => item.prefix !== prefix);
  return localStorage.setItem(API_KEYS, JSON.stringify(apiKeys));
};
