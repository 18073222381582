import React, { useEffect, useState } from "react";
import { Card, Collapse, List, ListItem, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useGetTransactionDetail } from "./hooks";
import SoftTypography from "../../components/SoftTypography";
import _ from "lodash";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1),
  borderLeft: `1px dashed ${theme.palette.grey[400]}`,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),

  ":hover": {
    background: theme.palette.grey[100],
  },
}));
// eslint-disable-next-line react/prop-types
export const TreeNode = ({ name, value, openNodes, setOpenNodes }) => {
  // eslint-disable-next-line react/prop-types
  const isOpen = openNodes.includes(name);
  const [open, setOpen] = useState(isOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (open) {
      setOpenNodes((prevOpenNodes) => [...prevOpenNodes, name]);
    } else {
      setOpenNodes((prevOpenNodes) =>
        prevOpenNodes.filter((nodeName) => nodeName !== name)
      );
    }
  }, [name, open, setOpenNodes]);

  if (value === null || typeof value === "undefined") {
    return null;
  }

  if (typeof value === "string" || typeof value === "number") {
    return (
      <StyledListItem>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          width="100%"
          gap={2}
        >
          <SoftTypography variant="body2">{_.startCase(name)}</SoftTypography>
          <SoftTypography variant="body2" color="secondary">
            {value}
          </SoftTypography>
        </SoftBox>
      </StyledListItem>
    );
  }

  const children = Object.entries(value).map(([childName, childValue]) => (
    <TreeNode
      key={childName}
      name={childName}
      value={childValue}
      openNodes={openNodes}
      setOpenNodes={setOpenNodes}
    />
  ));

  return (
    <>
      <StyledListItem button onClick={handleClick}>
        <SoftTypography variant="body2" color="info" textGradient>
          {_.startCase(name)}
        </SoftTypography>
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ pl: 2 }}>
          {children}
        </List>
      </Collapse>
    </>
  );
};
// eslint-disable-next-line react/prop-types
const TreeView = ({ data }) => {
  const [openNodes, setOpenNodes] = useState(["Transaction"]);

  return (
    <List component="nav">
      <TreeNode
        name="Transaction"
        value={data}
        openNodes={openNodes}
        setOpenNodes={setOpenNodes}
      />
    </List>
  );
};

// This code gets the transaction detail from the API and renders it as a tree view.
const TransactionDetail = () => {
  const { id } = useParams();
  const { data } = useGetTransactionDetail(id);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <MainLayout>
      <MainNavbar />
      <Card>
        {/*<Stack flexDirection={"row-reverse"} sx={{ mr: 3 }}>*/}
        {/*  <SoftButton*/}
        {/*    component={Link}*/}
        {/*    variant="gradient"*/}
        {/*    color="info"*/}
        {/*    sx={{ mt: 2 }}*/}
        {/*    to={`/transactions/edit/${id}`}*/}
        {/*  >*/}
        {/*    Edit Transaction*/}
        {/*  </SoftButton>*/}
        {/*</Stack>*/}

        <SoftBox p={4}>
          <TreeView data={data || {}} />
        </SoftBox>
        <SoftButton variant="gradient" onClick={handleGoBack}>
          <ArrowBackIosIcon />
          Back
        </SoftButton>
      </Card>
    </MainLayout>
  );
};

export default TransactionDetail;
