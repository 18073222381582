import { useMutation } from "react-query";
import { getQueryParam, getTemplatePath } from "../../utils/string";
import { toastError, toastSuccess } from "../../utils/toast";
import _ from "lodash";

const { default: request } = require("../../utils/request");

const GET_ALL_TRANSACTIONS = "/transactions";
const SEND_NOTIFICATION = "/notifications/send/:transactionId";
const REFUND = "/refund/by-id";

const getReport = async (filterTransactionProps) => {
  const URL = getTemplatePath(GET_ALL_TRANSACTIONS, filterTransactionProps);
  const params = getQueryParam(_.omit(filterTransactionProps));
  return request.get(`${URL}?${params}`);
};
const sendNotification = ({ transactionId }) => {
  const URL = getTemplatePath(SEND_NOTIFICATION, { transactionId });
  return request.post(URL);
};
const refund = ({ transactionId, amount }) => {
  return request.post(REFUND, { transactionId, amount });
};

export const useGetReporting = () => {
  return useMutation(getReport, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

export const useSendNotification = () => {
  return useMutation(sendNotification, {
    onSuccess: () => {
      toastSuccess({ description: "Send notification successfully" });
    },
  });
};

export const useRefund = () => {
  return useMutation(refund, {
    onSuccess: () => {
      toastSuccess({ description: "Send refund successfully" });
    },
  });
};
