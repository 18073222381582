import { Divider, Grid } from "@mui/material";
import { CurrencySelect } from "../../../components/FormField";
import TextInputField from "../../../components/FormField/TextInputField";
import React, { useEffect, useState } from "react";
import SoftButton from "../../../components/SoftButton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PaymentIcon from "@mui/icons-material/Payment";
import { schema } from "../schemas/payment";

const PaymentForm = ({ handleNextStep, watch }) => {
  const { amount, currency } = watch();
  const [validate, setValidate] = useState(false);
  useEffect(() => {
    schema
      .isValid({ amount, currency })
      .then((isValid) => {
        setValidate(isValid);
      })
      .catch((error) => {
        console.error("Validation error:", error);
      });
  }, [amount, currency]);

  return (
    <>
      <Grid container display="flex" gap={2} alignItems="center">
        <PaymentIcon />
        <Typography variant="h6">Payment</Typography>
      </Grid>
      <Divider textAlign="left" />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Amount *" name="amount" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CurrencySelect
            name="currency"
            label="Currency *"
            placeholder="Select currency"
            isClearable
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Merchant Reference" name="merchantReference" />
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <SoftButton
          variant="gradient"
          color="info"
          type="submit"
          disabled={!validate}
        >
          Generate Link
        </SoftButton>
        <SoftButton
          variant="gradient"
          color={"info"}
          onClick={handleNextStep}
          disabled={!validate}
        >
          Customise
        </SoftButton>
      </Grid>
    </>
  );
};

export default PaymentForm;

PaymentForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};
