import React, { forwardRef } from "react";

// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { FormControlLabel, Switch } from "@mui/material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const SwitchField = forwardRef(({ name, disabled, ...rest }, ref) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      ref={ref}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch {...field} disabled={disabled} checked={field.value} />
          )}
        />
      }
      {...rest}
    />
  );
});

// Setting default values for the props of SwitchField
SwitchField.defaultProps = {
  name: "",
  disabled: false,
};

// Typechecking props for the SwitchField
SwitchField.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
export default SwitchField;
