import React, { useEffect, useState } from "react";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import GenerateLinkModal from "./components/GenerateLinkModal";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import { useGetLinks, useGetBrands } from "./hooks";
import SoftSelect from "../../components/SoftSelect";
import _ from "lodash";
import Customer from "./components/Customer";
import DataTable from "../../examples/Tables/DataTable";
import DeleteLink from "./components/DeleteLink";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { toastSuccess } from "../../utils/toast";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import GenerateSettings from "./components/GenerateSettings";
import { getToken } from "../../utils/cache";
import jwtDecode from "jwt-decode";
import { useGetOneUser } from "../settings/users/hooks";

const PayByLink = () => {
  const [link, setLink] = useState("");
  const [currentBrand, setCurrentBrand] = useState({});
  const adminInfo = getToken() && jwtDecode(getToken());
  const { data: brands } =
    adminInfo?.role === "ACCOUNT"
      ? useGetOneUser(adminInfo?.sub)
      : useGetBrands();

  useEffect(() => {
    if (brands?.length || !Array.isArray(brands)) {
      adminInfo.role === "ACCOUNT"
        ? setCurrentBrand({
            value: adminInfo.brandId,
            label: brands?.brand,
          })
        : setCurrentBrand({
            value: brands?.length && brands[0]?._id,
            label: brands?.length && brands[0]?.name,
          });
    }
  }, [brands]);
  const handleChange = (brand) => {
    setCurrentBrand(brand);
  };
  const brandOptions = Array.isArray(brands)
    ? [{ value: "", label: "All" }]
        .concat(
          _.map(brands, (item) => ({ value: item._id, label: item.name }))
        )
        .filter((i) => i.label !== "All")
    : [
        {
          value: adminInfo?.brandId,
          label: brands?.brand,
        },
      ];
  const { data: links, isLoading } = useGetLinks(currentBrand.value);
  const getRows = () => {
    return _.map(links?.docs, (item) => ({
      id: item._id,
      link: item.link,
      customer: <Customer customer={item.payload?.customer} />,
      createdAt: item.createdAt,
      actions: (
        <SoftBox display="flex" gap={1} sx={{ alignItems: "center" }}>
          <DeleteLink payByLinkId={item._id} brandId={currentBrand.value} />
        </SoftBox>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      {brands && (brands?.length || !Array.isArray(brands)) ? (
        <>
          {" "}
          <Grid
            item
            xs={9}
            sx={{
              background: adminInfo.role === "ACCOUNT" ? "#3447671c" : "white",
              mt: 3,
              borderRadius: "0.5rem",
            }}
          >
            <SoftSelect
              defaultValue={
                adminInfo.role === "ACCOUNT"
                  ? {
                      value: adminInfo?.brandId,
                      label: brands?.brand,
                    }
                  : brands?.length && {
                      value: brands[0]?._id,
                      label: brands[0]?.name,
                    }
              }
              isDisabled={adminInfo.role === "ACCOUNT"}
              options={brandOptions}
              onChange={handleChange}
              sx={{ width: "100%!important" }}
            />
          </Grid>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <Grid item>
              <GenerateSettings brandId={currentBrand.value} />
            </Grid>
            <Grid item>
              <GenerateLinkModal
                brandId={currentBrand.value}
                onSuccess={setLink}
              />
            </Grid>
          </Grid>
          <Card sx={{ mt: 2, mb: 5 }}>
            <SoftBox p={3}>
              <SoftTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
              >
                Link for pay
              </SoftTypography>
              {link ? (
                <>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={7}
                      md={9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item>
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          size="md"
                        >
                          {link}
                        </SoftTypography>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Copy">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(link);
                              toastSuccess({
                                description: "Link copied successfully",
                              });
                            }}
                          >
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      md={3}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                          mt: "-24px",
                        }}
                      >
                        <Grid sx={{ display: "flex" }}>
                          <SoftTypography
                            variant="span"
                            fontWeight="regular"
                            color="text"
                            size="xs"
                            sx={{ fontSize: "14px" }}
                          >
                            Share by:
                          </SoftTypography>
                        </Grid>
                        <Grid sx={{ display: "flex", gap: "7px" }}>
                          <TelegramShareButton url={link}>
                            <TelegramIcon
                              size={28}
                              round={true}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                          </TelegramShareButton>
                          <WhatsappShareButton url={link}>
                            <WhatsappIcon
                              size={28}
                              round={true}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                          </WhatsappShareButton>
                          <EmailShareButton url={link}>
                            <EmailIcon
                              size={28}
                              round={true}
                              style={{ display: "flex", alignItems: "center" }}
                            />
                          </EmailShareButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </SoftBox>
          </Card>
          <DataTable
            table={{
              columns: [
                { Header: "ID", accessor: "id" },
                { Header: "Link", accessor: "link" },
                { Header: "Customer", accessor: "customer" },
                { Header: "Created time", accessor: "createdAt" },
                { Header: "Actions", accessor: "actions" },
              ],
              rows: getRows(),
            }}
            isLoading={isLoading}
          />
        </>
      ) : adminInfo?.role === "ACCOUNT" ? (
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <CircularProgress color={"secondary"} />
        </SoftBox>
      ) : (
        <SoftBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
          }}
        >
          <SoftTypography variant="h3" fontWeight="regular" color="text">
            Create at least one brand to link.
          </SoftTypography>
        </SoftBox>
      )}
    </MainLayout>
  );
};

export default PayByLink;
