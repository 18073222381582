export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const get = (key) => {
  const response =
    typeof window !== "undefined" && localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key) ?? "")
      : null;
  return response;
};
