import { Divider, Grid } from "@mui/material";
import { SwitchField } from "../../../components/FormField";
import TextInputField from "../../../components/FormField/TextInputField";
import React, { useEffect, useState } from "react";
import SoftButton from "../../../components/SoftButton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useUpdateSettings } from "../hooks";
import { schema } from "../schemas/settingsForm";

const GeneralSettingsForm = ({
  handleGoBack,
  settingsForm = false,
  brandId,
  methods,
}) => {
  const { mutate } = useUpdateSettings(brandId);

  const {
    enabled,
    expiresIn,
    merchantSpecificText,
    thankYouMessage,
    welcomeMessage,
  } = methods.watch();
  const [validate, setValidate] = useState(false);
  useEffect(() => {
    schema
      .isValid({
        expiresIn,
        merchantSpecificText,
        thankYouMessage,
        welcomeMessage,
      })
      .then((isValid) => {
        setValidate(isValid);
      })
      .catch((error) => {
        console.error("Validation error:", error);
      });
  }, [
    enabled,
    expiresIn,
    merchantSpecificText,
    thankYouMessage,
    welcomeMessage,
  ]);
  useEffect(() => {
    if (!expiresIn && expiresIn !== null) {
      methods?.setValue("expiresIn", null);
    }
  }, [expiresIn]);

  // create a function that handles the form submission
  const onSubmit = async () => {
    const { expiresIn, merchantSpecificText, thankYouMessage, welcomeMessage } =
      methods.getValues();
    const payload = {
      expiresIn: expiresIn ? Number(expiresIn) : null,
      merchantSpecificText,
      thankYouMessage,
      welcomeMessage,
    };
    mutate({ brandId, payload });
  };
  return (
    <>
      <Grid
        container
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent={settingsForm ? "start" : "space-between"}
      >
        <Grid sx={{ display: "flex", gap: "15px" }}>
          <SettingsOutlinedIcon />
          <Typography variant="h6">General Settings</Typography>
        </Grid>
        {settingsForm ? null : (
          <SoftButton variant="gradient" color={"light"} onClick={onSubmit}>
            Save Settings
          </SoftButton>
        )}
      </Grid>
      <Divider />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Welcome Message" name="welcomeMessage" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Thank You Message" name="thankYouMessage" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField
            label="Merchant Specific Text"
            name="merchantSpecificText"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField
            label="Expires In (days)"
            name="expiresIn"
            type={"number"}
          />
        </Grid>
        {settingsForm ? null : (
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "10px",
            }}
          >
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <SwitchField label="Enabled" name="enabled" />
            </Grid>
          </Grid>
        )}
      </Grid>
      {settingsForm ? null : (
        <Grid
          container
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="20px"
        >
          <SoftButton variant="gradient" onClick={handleGoBack}>
            Back
          </SoftButton>
          <SoftButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={!validate}
          >
            Generate Link
          </SoftButton>
        </Grid>
      )}
    </>
  );
};

export default GeneralSettingsForm;

GeneralSettingsForm.propTypes = {
  handleGoBack: PropTypes.func,
  settingsForm: PropTypes.bool,
  brandId: PropTypes.string,
  methods: PropTypes.any,
};
