import _ from "lodash";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import PropTypes from "prop-types";
import SoftBox from "../SoftBox";
import React from "react";

import WrapperItem from "./WrapperItem";
import SoftTypography from "../SoftTypography";
import { actionIcons } from "../../constans/actions";

function ActionCell({
  actions,
  onVisibilityClick,
  onEditClick,
  onDeleteClick,
  onResetClick,
  onNotificationClick,
  onWithdrawalClick,
  onApproveClick,
  onDeclineClick,
}) {
  const handleClick = (type) => {
    switch (type) {
      case "visibility":
        onVisibilityClick();
        break;
      case "edit":
        onEditClick();
        break;
      case "delete":
        onDeleteClick();
        break;
      case "reset":
        onResetClick();
        break;
      case "notification":
        onNotificationClick();
        break;
      case "approve":
        onApproveClick();
        break;
      case "decline":
        onDeclineClick();
        break;
      case "withdrawal":
        onWithdrawalClick();
        break;
      default:
        break;
    }
  };

  return (
    <SoftBox display="flex" alignItems="center">
      {_.map(actions, (action, key) => {
        return (
          <WrapperItem index={key} key={key} actionsLength={actions.length}>
            <SoftTypography
              variant="body1"
              color="secondary"
              sx={{ cursor: "pointer", lineHeight: 0 }}
            >
              <Tooltip
                title={action.title}
                placement="top"
                onClick={() => handleClick(action.type)}
              >
                <Icon
                  sx={{
                    color: (theme) =>
                      action.type === "approve"
                        ? theme.palette.success.main
                        : action.type === "decline"
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
                  }}
                >
                  {actionIcons[action.type]}
                </Icon>
              </Tooltip>
            </SoftTypography>
          </WrapperItem>
        );
      })}
    </SoftBox>
  );
}

// Setting default values for the props of ActionCell
ActionCell.defaultProps = {
  actions: [],
  onVisibilityClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
  onResetClick: () => {},
  onWithdrawalClick: () => {},
  onNotificationClick: () => {},
  onApproveClick: () => {},
  onDeclineClick: () => {},
};

// Typechecking props of the ActionCell
ActionCell.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onVisibilityClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onResetClick: PropTypes.func,
  onWithdrawalClick: PropTypes.func,
  onNotificationClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  onDeclineClick: PropTypes.func,
};

export default ActionCell;
