/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect, useMemo, useState } from "react";

// react-router components
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "./routes";

// Soft UI Dashboard PRO React contexts
import { setMiniSidenav, useSoftUIController } from "./context";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import SignIn from "./pages/sign-in";
import ForgotPassword from "./pages/sign-in/ForgotPassword";
import ChangePassword from "./pages/sign-in/СhangePassword";
import TransactionDetail from "./pages/transaction-detail";
import BrandDetail from "./pages/brands/[id]";
import { getToken } from "./utils/cache";
import jwtDecode from "jwt-decode";

const queryClient = new QueryClient();
const ROOTDOMAIN = window.location.host.split(".").slice(-2).join(".");

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [accessRouts, setAccessRouts] = useState(routes);
  const adminInfo = getToken() && jwtDecode(getToken());
  useEffect(() => {
    setAccessRouts(newRoutes);
  }, []);

  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const newRoutes = routes?.filter((route) => {
    if (adminInfo?.role === "ACCOUNT") {
      return (
        route?.route !== "/accounts" &&
        route?.route !== "/brands" &&
        route?.route !== "/settings"
      );
    }
    return true;
  });

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
  useEffect(() => {
    const domainName =
      ROOTDOMAIN.split(".")[0].charAt(0).toUpperCase() +
      ROOTDOMAIN.split(".")[0].slice(1);
    document.title = `Merchant Account Portal | ${domainName}`;
  }, [ROOTDOMAIN]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes?.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  };

  const customRoutes = () => {
    return (
      <>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/transactions/:id" element={<TransactionDetail />} />
        <Route path="/brands/:id" element={<BrandDetail />} />
        <Route path="*" element={<Navigate to="/pay-by-link" />} />
      </>
    );
  };

  return direction === "rtl" ? (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          <ToastContainer />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={"/transactify.svg"}
                brandName="Merchant Account Portal"
                routes={accessRouts}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>{getRoutes(accessRouts)}</Routes>
          {customRoutes()}
        </ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  ) : (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={"/transactify.svg"}
              brandName="Merchant Account Portal"
              routes={accessRouts}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(accessRouts)}
          <Route path="/sign-in" element={<SignIn />} />
          {customRoutes()}
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
