import * as Yup from "yup";

export const defaultValuesAccount = {
  name: null,
  surname: null,
  email: null,
  role: null,
  brandId: null,
};

export const schemaAccount = Yup.object().shape({
  name: Yup.string()
    .required("Merchant name is required")
    .max(100, "Merchant name is too long, should be less than 100 characters"),
  surname: Yup.string()
    .required("Surname is required")
    .max(100, "Surname is too long, should be under 100 characters"),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required("Role is required"),
  brandId: Yup.string().required("Brand is required"),
});
