import request from "../../utils/request";
import { toastSuccess } from "../../utils/toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GET_BRANDS } from "../pay-by-link/hooks";
import { getTemplatePath } from "../../utils/string";

const CREATE_BRAND = "/brands";
const GET_BRAND_INFO = "/brands/:brandId";
const UPDATE_BRAND_INFO = "/brands/:brandId";
const DELETE_BRAND = "/brands/:brandId";

const createBrand = (data) => {
  return request.post(CREATE_BRAND, data);
};

const getBrandInfo = (brandId) => {
  const URL = getTemplatePath(GET_BRAND_INFO, { brandId });
  return request.get(URL);
};

const updateBrandInfo = (data) => {
  const URL = getTemplatePath(UPDATE_BRAND_INFO, { brandId: data.id });
  return request.put(URL, data);
};

const deleteBrand = ({ brandId }) =>
  request.delete(getTemplatePath(DELETE_BRAND, { brandId }));
export const useGetBrandInfo = (id) => {
  return useQuery([GET_BRAND_INFO, id], () => getBrandInfo(id), {
    enabled: !!id,
  });
};
export const useCreateBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(createBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Create brand has been success" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};

export const useUpdateBrandInfo = () => {
  return useMutation(updateBrandInfo, {
    onSuccess: () => {
      toastSuccess({ description: "Update Brand info has been success" });
    },
  });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBrand, {
    onSuccess: () => {
      toastSuccess({ description: "Brand deleted successfully" });
      queryClient.invalidateQueries([GET_BRANDS]);
    },
  });
};
