import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import PaymentIcon from "@mui/icons-material/Payment";

import { useRefund } from "../hooks";
import React, { useEffect } from "react";
import SoftBox from "../../../components/SoftBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import SoftTypography from "../../../components/SoftTypography";
import FormProvider from "../../../components/FormProviders";
import SoftButton from "../../../components/SoftButton";
import { TextInputField } from "../../../components/FormField";
import StatusChip from "../../../components/StatusChip";
import _ from "lodash";
import Customer from "../../pay-by-link/components/Customer";

const sanitizeRequest = ({ amount }) => {
  return _.pickBy(
    {
      amount:
        amount && typeof amount === "number" ? amount : parseFloat(amount),
    },
    _.identity
  );
};
const RefundModal = ({ isOpen, onClose, transaction }) => {
  //form provider
  const methods = useForm();
  const { handleSubmit, setValue } = methods;
  // //hooks
  const { isLoading, mutate } = useRefund();
  // // create a function that handles the form submission
  const onSubmit = ({ amount }) => {
    mutate(
      {
        transactionId: _.get(transaction, "id"),
        ...sanitizeRequest({ amount }),
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  useEffect(() => {
    setValue("amount", _.get(transaction, "amount"));
  }, [transaction]);

  const renderTransactionDetail = () => (
    <Grid item xs={12} sm={12}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={2}>
            <PaymentIcon
              sx={{
                width: pxToRem(48),
                height: pxToRem(48),
                color: (theme) => theme.palette.secondary.main,
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <SoftTypography variant="caption">
                <b>Type:</b> &nbsp;{_.get(transaction, "type")}
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Amount:</b> &nbsp;{_.get(transaction, "amount")}
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Currency:</b> &nbsp;{_.get(transaction, "currency")}
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Country:</b> &nbsp;{_.get(transaction, "country")}
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Payment Option:</b> &nbsp;
                {_.get(transaction, "paymentOption")}
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Merchant Reference:</b> &nbsp;
                {_.get(transaction, "merchantReference")}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={5}>
            <SoftBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <SoftTypography variant="caption">
                <b>Status:</b> &nbsp;{" "}
                <StatusChip label={_.get(transaction, "status")} />
              </SoftTypography>
              <SoftTypography variant="caption">
                <b>Customer:</b> &nbsp;{" "}
                <Box ml={5}>
                  <Customer customer={_.get(transaction, "customer", {})} />
                </Box>
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Grid>
  );

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Refund Transaction
          </DialogTitle>
          <DialogContent>
            <SoftBox p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Refund
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Are you sure you want to refund this transaction{" "}
                    <b>{_.get(transaction, "id")}</b>?
                  </SoftTypography>
                </Grid>
                {renderTransactionDetail()}
                <Grid item xs={2} sm={2}></Grid>
                <Grid item xs={8} sm={8}>
                  <Divider />
                  <TextInputField
                    name="amount"
                    label="Amount"
                    type="number"
                    inputProps={{
                      maxLength: 13,
                      step: "0.0001",
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Refund
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Setting default values for the props of RefundModal
RefundModal.defaultProps = {
  isOpen: false,
};

// Typechecking props for the RefundModal
RefundModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object,
};

export default RefundModal;
