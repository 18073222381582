export const StatusEnum = {
  INIT: "INIT",
  PENDING: "PENDING",
  USER_CANCEL: "USER_CANCEL",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  DECLINED: "DECLINED",
  EXPIRED: "EXPIRED",
  REFUNDED: "REFUNDED",
  CAPTURED: "CAPTURED",
  VOIDED: "VOIDED",
  USER_ABANDONED: "USER_ABANDONED",
  CHARGEBACK: "CHARGEBACK",
};

export const StatusColorEnum = {
  INIT: "default",
  PENDING: "secondary",
  USER_CANCEL: "warning",
  PROCESSING: "secondary",
  COMPLETED: "success",
  ERROR: "error",
  DECLINED: "error",
  EXPIRED: "error",
  REFUNDED: "warning",
  CAPTURED: "warning",
  VOIDED: "warning",
  USER_ABANDONED: "warning",
};

export const EnabledColorEnum = {
  true: "success",
  false: "error",
};
