import * as yup from "yup";

export const defaultValues = {
  merchantSpecificText: null,
  thankYouMessage: null,
  welcomeMessage: null,
  expiresIn: null,
};
export const schema = yup.object().shape({
  expiresIn: yup
    .mixed()
    .test(
      "is-valid-number",
      "Expires In must be a valid number greater than 0",
      function (value) {
        if (value === null) {
          return true; // Null is allowed
        }
        const numericValue = Number(value);
        if (!isNaN(numericValue) && numericValue > 0) {
          return true; // Numeric value greater than 0 is allowed
        }
        return false;
      }
    )
    .nullable()
    .transform((value) => {
      if (value === "") {
        return null; // Convert empty string to null
      }
      return value;
    }),
  merchantSpecificText: yup.string().nullable(),
  thankYouMessage: yup.string().nullable(),
  welcomeMessage: yup.string().nullable(),
});
