import { Card, Grid, DialogActions } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useGetBrandInfo, useUpdateBrandInfo } from "../hooks";
import { defaultValues, schema } from "../schemas/brand";
import MainLayout from "../../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../../examples/Navbars/MainNavbar";
import SoftBox from "../../../components/SoftBox";
import FormProvider from "../../../components/FormProviders";
import BrandForm from "../BrandForm";
import SoftButton from "../../../components/SoftButton";
import DeleteBrandModal from "../DeleteBrandModal";
const BrandDetail = () => {
  const { id } = useParams();
  const { data } = useGetBrandInfo(id);
  const { mutate, isLoading } = useUpdateBrandInfo();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset } = methods;

  const handleSubmitForm = (values) => {
    mutate({ ...values, id });
  };

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <MainLayout>
      <MainNavbar />
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card sx={{ overflow: "visible" }}>
            <SoftBox p={3}>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <BrandForm />
                <DialogActions style={{ justifyContent: "flex-start" }}>
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid>
                      <SoftButton onClick={handleGoBack}>
                        <ArrowBackIosIcon />
                        Back
                      </SoftButton>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: 2 }}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        sx={{ mt: 2 }}
                        disabled={isLoading}
                        isLoading={isLoading}
                      >
                        Update
                      </SoftButton>
                      <DeleteBrandModal brand={data} />
                    </Grid>
                  </Grid>
                </DialogActions>
              </FormProvider>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default BrandDetail;
