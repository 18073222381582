import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import SoftBox from "../../../components/SoftBox";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../users/schemas/merchant";
import { useCreateManageUser } from "../users/hooks";
import FormProvider from "../../../components/FormProviders";
import ManageUserForm from "./ManageUserForm";
import {
  defaultValuesAccount,
  schemaAccount,
} from "../../accounts/schemas/account";
import PropTypes from "prop-types";

const AddManageUserModal = ({ brand, role }) => {
  const [isOpenAddMerchantModal, setIsOpenAddMerchantModal] = useState(false);
  //form provider
  const methods = useForm({
    resolver: yupResolver(brand ? schemaAccount : schema),
    defaultValues: brand ? defaultValuesAccount : defaultValues,
  });
  const { handleSubmit, setValue } = methods;
  useEffect(() => {
    brand ? setValue("role", "ACCOUNT") : setValue("role", "ADMIN");
  }, [brand]);

  //hooks
  const { isLoading, mutate } = useCreateManageUser();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(values, {
      onSuccess: () => {
        handleCloseAddMerchantModal();
      },
    });
  };
  const handleOpenAddMerchantModal = () => {
    setIsOpenAddMerchantModal(true);
  };
  const handleCloseAddMerchantModal = () => {
    setIsOpenAddMerchantModal(false);
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleOpenAddMerchantModal}
          >
            {role === "ADMIN" ? "Add Admin" : "Add Account"}
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenAddMerchantModal}
        onClose={handleCloseAddMerchantModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            {role === "ADMIN" ? "Create New Admin" : "Create New Account"}
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3} pb={0}>
              <ManageUserForm brand={brand} />
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              pt: 0,
            }}
          >
            <SoftButton
              variant="gradient"
              onClick={handleCloseAddMerchantModal}
            >
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              {role === "ADMIN" ? "Create Admin" : "Create Account"}
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddManageUserModal;

AddManageUserModal.propTypes = {
  brand: PropTypes.string,
  role: PropTypes.string,
};
