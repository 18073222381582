import _ from "lodash";
export const getTemplatePath = (url, params) => {
  // Set the placeholder syntax to match the ":" syntax
  _.templateSettings.interpolate = /:([\w]+)/g;

  const compiled = _.template(url);
  const result = compiled(params);
  return result;
};

export const getQueryParam = (obj) => {
  // Remove empty keys from the object
  const queryParams = _.toPairs(obj)

    .map(([key, value]) => {
      if (!value) {
        return "";
      }
      if (key === "toDate" || key === "fromDate") {
        const tempValue =
          typeof value === "string" ? value : value?.toISOString();
        return `${key}=${tempValue}`;
      }
      return `${key}=${value}`;
    })
    .join("&");
  return queryParams;
};
