import { useMutation, useQuery, useQueryClient } from "react-query";
import { toastSuccess } from "../../utils/toast";
import request from "../../utils/request";
import { getTemplatePath } from "../../utils/string";
import jwtDecode from "jwt-decode";
import { getToken } from "../../utils/cache";

//get links
const GET_LINKS = "/brand/:brandId/pay-by-link";
const getLinks = (brandId) => {
  return request.get(getTemplatePath(GET_LINKS, { brandId }));
};

export const useGetLinks = (brandId) => {
  return useQuery([GET_LINKS, brandId], () => getLinks(brandId), {
    enabled: !!brandId,
  });
};

//get users
const GET_SETTINGS = "/brand/:brandId/pay-by-link/settings";
const getSettings = (brandId) => {
  return request.get(getTemplatePath(GET_SETTINGS, { brandId }));
};

export const useGetSettings = (brandId) => {
  return useQuery([GET_SETTINGS, brandId], () => getSettings(brandId), {
    enabled: !!brandId,
  });
};
//update users

const UPDATE_SETTINGS = "/brand/:brandId/pay-by-link/settings";
const updateSettings = ({ brandId, payload }) => {
  return request.put(getTemplatePath(UPDATE_SETTINGS, { brandId }), payload);
};

export const useUpdateSettings = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation(updateSettings, {
    onSuccess: () => {
      toastSuccess({ description: "Update users has been success" });
      queryClient.invalidateQueries([GET_SETTINGS, brandId]);
    },
  });
};

//create link

const CREATE_LINK = "/brand/:brandId/pay-by-link";
const createLink = ({ brandId, payload }) => {
  return request.post(getTemplatePath(CREATE_LINK, { brandId }), payload);
};

export const useCreateLink = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation(createLink, {
    onSuccess: () => {
      toastSuccess({ description: "Create link has been success" });
      queryClient.invalidateQueries([GET_LINKS, brandId]);
    },
  });
};

//delete link
const DELETE_LINK = "/brand/:brandId/pay-by-link/:payByLinkId";

const deleteLink = ({ brandId, payByLinkId }) => {
  return request.delete(getTemplatePath(DELETE_LINK, { brandId, payByLinkId }));
};

export const useDeleteLink = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation(deleteLink, {
    onSuccess: () => {
      toastSuccess({ description: "Link deleted successfully" });
      queryClient.invalidateQueries([GET_LINKS, brandId]);
    },
  });
};

// edit link
const EDIT_LINK =
  "/merchants/:merchantId/brand/:brandId/pay-by-link/:payByLinkId";
const editLink = ({ brandId, payByLinkId, payload }) => {
  const merchantId = jwtDecode(getToken())?.merchantId;
  return request.post(
    getTemplatePath(EDIT_LINK, { merchantId, brandId, payByLinkId }),
    payload
  );
};

export const useEditLink = (brandId) => {
  const queryClient = useQueryClient();
  const merchantId = jwtDecode(getToken())?.merchantId;

  return useMutation(editLink, {
    onSuccess: () => {
      toastSuccess({ description: "Edit link has been success" });
      queryClient.invalidateQueries([GET_LINKS, merchantId, brandId]);
    },
  });
};
// brands hooks
export const GET_BRANDS = "/brands";

const getBrands = () => {
  return request.get(GET_BRANDS);
};
export const useGetBrands = () => {
  return useQuery([GET_BRANDS], () => getBrands());
};
