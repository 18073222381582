import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import _ from "lodash";
import SoftButton from "../../components/SoftButton";
import FormProvider from "../../components/FormProviders";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import pxToRem from "../../assets/theme/functions/pxToRem";
import { useDeleteBrand } from "./hooks";

const DeleteBrandModal = ({ brand }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading, mutate } = useDeleteBrand();

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // create a function that handles the form submission
  const onSubmit = () => {
    mutate(
      { brandId: brand._id },
      {
        onSuccess: () => {
          handleClose();
          navigate("/brands");
        },
      }
    );
  };

  return (
    <>
      <SoftButton
        variant="gradient"
        color="error"
        sx={{ mt: 2 }}
        onClick={handleOpen}
      >
        Delete
      </SoftButton>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Delete Brand
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <DeleteForeverIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Delete Brand
                          </SoftTypography>
                          <SoftTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                          >
                            Are you sure you want to delete brand{" "}
                            {_.get(brand, "name")}? you will not be able to
                            recover this brand?
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
            >
              Delete Brand
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Typechecking props for the DeleteMerchantModal
DeleteBrandModal.propTypes = {
  brand: PropTypes.object.isRequired,
};

export default DeleteBrandModal;
