import * as Yup from "yup";

export const defaultValues = {
  name: null,
  kashaSignature: null,
  kashaApiKey: null,
};

export const schema = Yup.object().shape({
  kashaSignature: Yup.string()
    .required("Signature is required")
    .max(2000, "Signature is too long, should be less than 2000 characters")
    .nullable(),
  kashaApiKey: Yup.string()
    .required("Api Key is required")
    .max(2000, "Api Key is too long, should be less than 2000 characters")
    .nullable(),
  name: Yup.string()
    .required("Brand name is required")
    .max(100, "Brand name is too long, should be less than 100 characters")
    .nullable(),
});
