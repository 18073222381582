import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import {
  SelectInputField,
  TextInputField,
} from "../../../components/FormField";
import { useGetBrands } from "../../pay-by-link/hooks";
import _ from "lodash";

const ManageUserForm = ({ brand }) => {
  const { data: brands } = useGetBrands();
  const brandOptions = _.map(brands, (item) => ({
    value: item._id,
    label: item.name,
  }));
  return (
    <Box>
      {brand && (
        <Grid item xs={12} sm={12}>
          <SelectInputField
            name="brandId"
            label="Brand"
            message="Required"
            isClearable
            options={brandOptions}
            placeholder="Select brand"
          />
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Surname" name="surname" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} display={"none"}>
          <SelectInputField
            name="role"
            label="Role"
            options={
              !brand
                ? [{ label: "ADMIN", value: "ADMIN" }]
                : [{ label: "ACCOUNT", value: "ACCOUNT" }]
            }
            placeholder="Select an option"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInputField label="Email" name="email" />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default ManageUserForm;
ManageUserForm.propTypes = {
  brand: PropTypes.string,
};
