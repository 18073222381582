import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import SoftBox from "../../../components/SoftBox";
import FormProvider from "../../../components/FormProviders";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import GenerateLinkForm from "./GenerateLinkForm";
import { useForm } from "react-hook-form";
import { useCreateLink, useGetSettings } from "../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../schemas/schema";
import { sanitizeRequest } from "../sanitizeRequest";

const GenerateLinkModal = ({ brandId, onSuccess }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { data: settings } = useGetSettings(brandId);
  useEffect(() => {
    if (settings) {
      const {
        expiresIn,
        merchantSpecificText,
        thankYouMessage,
        welcomeMessage,
      } = settings;
      setValue("expiresIn", expiresIn);
      setValue("merchantSpecificText", merchantSpecificText);
      setValue("thankYouMessage", thankYouMessage);
      setValue("welcomeMessage", welcomeMessage);
    }
  }, [settings, isOpenModal]);

  // form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, watch, reset, setValue } = methods;

  // hooks
  const { mutate } = useCreateLink(brandId);

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    mutate(
      { brandId, payload: sanitizeRequest(values) },
      {
        onSuccess: ({ link }) => {
          onSuccess(link);
          handleCloseModal();
        },
      }
    );
  };
  const handleOpenModal = () => {
    setIsOpenModal(true);
  };
  const handleCloseModal = () => {
    setIsOpenModal(false);
    reset(defaultValues);
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleOpenModal}
          >
            Generate Link
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog
        open={isOpenModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="lg"
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Generate Link for pay
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3} pb={0}>
              <GenerateLinkForm
                watch={watch}
                brandId={brandId}
                methods={methods}
              />
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: "20px",
            }}
          >
            <SoftButton variant="gradient" onClick={handleCloseModal}>
              Cancel
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default GenerateLinkModal;

GenerateLinkModal.propTypes = {
  brandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func.isRequired,
};
