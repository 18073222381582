import { Card, Grid } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import {
  DatePickerField,
  SelectInputField,
  TextInputField,
} from "../../../components/FormField";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import { StatusEnum } from "../../../constans/status";
import { getToken } from "../../../utils/cache";
import jwtDecode from "jwt-decode";

const MerchantReportingFilters = (props) => {
  const { onSubmit, brandOptions } = props;
  const now = new Date();
  const { setValue, getValues } = useFormContext();
  const adminInfo = getToken() && jwtDecode(getToken());
  useEffect(() => {
    if (adminInfo?.role === "ACCOUNT") {
      setValue("brandId", adminInfo?.brandId);
    }
  }, []);

  const dateTimePickerOptions = {
    allowInput: false,
    enableTime: true,
    maxDate: now,
    time_24hr: true,
  };

  const statusOptions = [{ value: "", label: "All" }].concat(
    _.map(StatusEnum, (item) => ({ value: item, label: item }))
  );
  const handleClickCleanFilters = async () => {
    // eslint-disable-next-line no-unused-vars
    const { limit, page, ...currentFilters } = await getValues(); // Get the current filter values
    // eslint-disable-next-line no-unused-vars
    const { brandId, ...filters } = currentFilters;

    await Object.keys(
      adminInfo?.role === "ACCOUNT" ? filters : currentFilters
    ).forEach((key) => {
      setValue(key, "");
    }); // Reset the values of all current filters
    await onSubmit();
  };
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  styles={{
                    backgroundColor:
                      adminInfo?.role === "ACCOUNT"
                        ? "green!important"
                        : "white",
                  }}
                  name="brandId"
                  label="Brand"
                  message="Required"
                  isDisabled={adminInfo?.role === "ACCOUNT"}
                  isClearable={!(adminInfo?.role === "ACCOUNT")}
                  options={brandOptions}
                  placeholder="Select brand"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Document ID" name="documentId" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  name="status"
                  label="Status"
                  options={statusOptions}
                  placeholder="Select an status"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Customer Name" name="customerName" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField
                  label="Merchant Reference"
                  name="merchantReference"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Email" name="email" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <TextInputField
                  label="Min Amount"
                  name="minAmount"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextInputField
                  label="Max Amount"
                  name="maxAmount"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions}
                  label="From Date"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DatePickerField
                  name="toDate"
                  options={dateTimePickerOptions}
                  label="To Date"
                />
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                sx={{ mt: 2 }}
              >
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={handleClickCleanFilters}
              >
                Clean filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MerchantReportingFilters;

// Typechecking props of the MerchantReportingFilters
MerchantReportingFilters.propTypes = {
  onSubmit: PropTypes.func,
  brandOptions: PropTypes.array,
};
