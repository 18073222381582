import React from "react";

import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SettingsIcon from "@mui/icons-material/Settings";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";

//pages
import PayByLink from "./pages/pay-by-link";
import MerchantAccounts from "./pages/accounts";
import MerchantReporting from "./pages/transactions";
import SettingsPage from "./pages/settings";
import Brands from "./pages/brands";

const routes = [
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "/transactions",
    icon: <EqualizerIcon size="12px" />,
    noCollapse: true,
    component: <MerchantReporting />,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    icon: <PeopleAltIcon size="12px" />,
    route: "/accounts",
    noCollapse: true,
    component: <MerchantAccounts />,
  },
  {
    type: "collapse",
    name: "Brands",
    key: "brands",
    icon: <BeenhereOutlinedIcon size="12px" />,
    route: "/brands",
    noCollapse: true,
    component: <Brands />,
  },
  {
    type: "collapse",
    name: "Pay by link",
    key: "pay-by-link",
    route: "/pay-by-link",
    icon: <InsertLinkIcon size="12px" />,
    noCollapse: true,
    component: <PayByLink />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
    component: <SettingsPage />,
  },
];

export default routes;
