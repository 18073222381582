/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components//SoftInput";
import SoftButton from "../../components/SoftButton";

// Image
import { useForm } from "react-hook-form";
import { useSignIn } from "./useAuth";
import React, { useEffect } from "react";
import { removeToken } from "../../utils/cache";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../utils/toast";
import SoftTypography from "../../components/SoftTypography";
import PageLayout from "../../examples/LayoutContainers/PageLayout";

function SignIn() {
  const { register, handleSubmit } = useForm({
    defaultValues: { email: "", password: "" },
  });
  const navigate = useNavigate();
  useEffect(() => {
    removeToken();
  }, []);

  const { mutate, isLoading } = useSignIn();

  const handleSubmitForm = (values) => {
    mutate(values, {
      onError: () => {
        toastError({
          description: `Invalid email or password!`,
        });
      },
    });
  };

  return (
    <PageLayout background="white">
      <SoftBox
        m={"auto"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
        component="form"
        role="form"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <SoftBox width={"350px"}>
          <SoftBox mb={1}>
            <SoftTypography variant="h4" fontWeight="bold">
              Sign In
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            Enter your email and password to sign in
          </SoftTypography>
          <SoftBox my={2}>
            <SoftInput
              type="email"
              placeholder="Email"
              size="large"
              {...register("email")}
            />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              type="password"
              placeholder="Password"
              size="large"
              {...register("password")}
            />
          </SoftBox>
          <SoftBox sx={{ display: "flex", justifyContent: "end" }}>
            <SoftButton
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{
                py: "0px!important",
                paddingRight: "0px!important",
                "&:hover": {
                  backgroundColor: "transparent!important",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot password?
            </SoftButton>
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              sign in
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </PageLayout>
  );
}

export default SignIn;
