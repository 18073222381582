import React, { useEffect, useState } from "react";

import { useGetReporting } from "./hooks";
import MerchantReportingFilters from "./components/MerchantReportingFilters";
import { useForm } from "react-hook-form";
import NotificationModal from "./components/NotificationModal";
import { Chip, Stack } from "@mui/material";
import { countries } from "../../constans/countries";
import StatusChip from "../../components/StatusChip";
import DataTable from "../../examples/Tables/DataTable";
import { get, set } from "../../utils/localStorage";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import FormProvider from "../../components/FormProviders";
import { fDateTimeSuffix } from "../../utils/formatTime";
import ActionCell from "../../components/ActionCell";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { getToken } from "../../utils/cache";
import RefundModal from "./components/RefundModal";
import { useNavigate } from "react-router-dom";
import { useGetBrands } from "../pay-by-link/hooks";
import Customer from "../pay-by-link/components/Customer";
import { useGetOneUser } from "../settings/users/hooks";

const LC_PAYLOAD = "merchantReportingPayload";
const ACTION_CELL = [
  {
    type: "visibility",
    title: "Transaction Details",
  },
  {
    type: "notification",
    title: "Send Notification",
  },
  {
    type: "withdrawal",
    title: "Refund",
  },
];

const ACTION = [
  {
    type: "visibility",
    title: "Transaction Details",
  },
  {
    type: "notification",
    title: "Send Notification",
  },
];

const sanitizeRequest = ({
  fromDate,
  toDate,
  brandId,
  limit,
  page,
  status,
  documentId,
  merchantReference,
  email,
  customerName,
  minAmount,
  maxAmount,
}) => {
  const identityObj = _.pickBy(
    {
      customerName,
      maxAmount,
      minAmount,
      email,
      brandId,
      merchantReference,
      documentId,
      status,
      fromDate,
      toDate,
      limit,
      page,
    },
    _.identity
  );
  return _.pickBy(identityObj, (value) => value !== "All");
};
const MerchantReporting = () => {
  const token = getToken();
  const [pageSize, setPageSize] = useState(10);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [dataToDownload, setDataToDownload] = useState([{}]);
  const [currentSelectedTransaction, setCurrentSelectedTransaction] = useState(
    {}
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [refundedModalOpen, setRefundedModalOpen] = useState(false);

  const { mutate: downloadMutate } = useGetReporting();

  const [selectedFilters, setSelectedFilters] = useState({});
  // Destructuring the object of all selected filters to obtain only the necessary
  // eslint-disable-next-line no-unused-vars
  const { page, merchantId, limit, ...filters } = selectedFilters;
  const { mutate, isLoading, data } = useGetReporting();
  const merchantReportingPayload = get(LC_PAYLOAD) || {};
  const merchant = jwtDecode(getToken());
  const defaultValues = merchantReportingPayload;
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;
  const navigate = useNavigate();
  const adminInfo = getToken() && jwtDecode(getToken());
  const { data: brands } =
    adminInfo?.role === "ACCOUNT"
      ? useGetOneUser(adminInfo?.sub)
      : useGetBrands();
  const brandOptions = Array.isArray(brands)
    ? _.map(brands, (item) => ({
        value: item._id,
        label: item.name,
      }))
    : [
        {
          value: adminInfo?.brandId,
          label: brands?.brand,
        },
      ];

  useEffect(() => {
    if (!token) window.location.href = "/sign-in";
    if (adminInfo?.role === "ACCOUNT")
      if (merchantReportingPayload) {
        updateSelectedFilters(merchantReportingPayload);
      }
  }, []);

  const getSelectedTransaction = (id) => {
    const transaction = _.find(_.get(data, "docs", []), { id });
    setCurrentSelectedTransaction(transaction);
  };

  const setRefundedModalClose = () => {
    setRefundedModalOpen(false);
    mutate(merchantReportingPayload);
  };

  // create a function that navigates to the detail page for a given transaction
  const handleNotificationClick = (id) => {
    getSelectedTransaction(id);
    setNotificationModalOpen(true);
  };
  const handleNotificationClose = () => {
    setNotificationModalOpen(false);
  };
  const handleRefundClick = (id) => {
    getSelectedTransaction(id);
    setRefundedModalOpen(true);
  };
  // create a function that navigates to the detail page for a given transaction
  const handleDetailClick = (id) => {
    navigate(`/transactions/${id}`);
  };

  // create a function that returns the rows for the table
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      country: _.get(_.find(countries, { code: item.country }), "label", "N/A"),
      currency: item.currency,
      amount: item.amount,
      status: <StatusChip label={item.status} size="small" />,
      merchantReference: item.merchantReference,
      createdAt: item.createdAt,
      customer: <Customer customer={item.customer} />,
      id: item.id,
      actions: (
        <ActionCell
          actions={item.status === "COMPLETED" ? ACTION_CELL : ACTION}
          onNotificationClick={() => handleNotificationClick(item.id)}
          onWithdrawalClick={() => handleRefundClick(item.id)}
          onVisibilityClick={() => handleDetailClick(item.id)}
        />
      ),
    }));
  };

  // create a function that update the list of selected filters
  const updateSelectedFilters = ({
    toDate,
    fromDate,
    documentId,
    merchantReference,
    brandId,
    minAmount,
    maxAmount,
    ...selected
  }) => {
    let time = { toDate: null, fromDate: null };
    if (toDate) {
      time.toDate = fDateTimeSuffix(toDate);
    }
    if (fromDate) {
      time.fromDate = fDateTimeSuffix(fromDate);
    }

    setSelectedFilters((prev) => {
      return {
        ...prev,
        ...selected,
        fromDate:
          fromDate &&
          `From ${time.fromDate + (toDate ? " to" + " " + time.toDate : "")} `,
        toDate: fromDate ? null : toDate && `To ${time.toDate}`,
        documentId: documentId && `Doc ID: ${documentId}`,
        merchantReference:
          merchantReference && `Merchant Reference: ${merchantReference}`,
        minAmount:
          minAmount &&
          `Amount from ${minAmount + (maxAmount ? " to " + maxAmount : "")}`,
        maxAmount: minAmount ? null : maxAmount && `Amount to ${maxAmount}`,
        brandId:
          brandId && brandOptions?.find((i) => i.value === brandId)?.label,
      };
    });
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    const payload = {
      ...values,
      limit: pageSize,
      page: 1,
      merchantId: merchant?.merchantId,
    };
    set(LC_PAYLOAD, payload);
    mutate(sanitizeRequest(payload));
    updateSelectedFilters(values);
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    const payload = merchantReportingPayload;
    payload.page = page + 1;
    set(LC_PAYLOAD, payload);
    setCurrentPage(page + 1);
    mutate(sanitizeRequest(payload));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    const payload = merchantReportingPayload;
    payload.page = 1;
    payload.limit = pageSize;
    const newPayload =
      adminInfo.role === "ACCOUNT"
        ? { ...payload, brandId: adminInfo?.brandId }
        : payload;
    set(LC_PAYLOAD, newPayload);
    setCurrentPage(1);
    mutate(sanitizeRequest(newPayload));
  };

  const handleDownloadClick = async ({ ref }) => {
    return new Promise(function (resolve) {
      // eslint-disable-next-line no-unused-vars
      const { limit, page, ...other } = merchantReportingPayload;
      const payload = {
        ...other,
        limit: _.get(data, "pagination.totalDocs", 0),
      };
      downloadMutate(payload, {
        onSuccess: async (data) => {
          setDataToDownload(_.get(data, "docs", []));
          await setTimeout(() => {
            ref.current?.link.click();
            resolve();
          }, 3000);
        },
      });
    });
  };

  return (
    <MainLayout>
      <MainNavbar />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <MerchantReportingFilters
          onSubmit={handleSubmit(onSubmit)}
          brandOptions={brandOptions}
        />
      </FormProvider>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: 2, mb: 2 }}
        display="flex"
        flexWrap="wrap"
        gap={1}
      >
        {Object.values(filters)?.map((i, index) =>
          i ? (
            <Chip
              key={i + index}
              label={i}
              color="secondary"
              textсolor="white"
            />
          ) : null
        )}
      </Stack>
      <DataTable
        table={{
          columns: [
            { Header: "Country", accessor: "country" },
            { Header: "Currency", accessor: "currency" },
            { Header: "Amount", accessor: "amount" },
            { Header: "Status", accessor: "status" },
            { Header: "Merchant Reference", accessor: "merchantReference" },
            { Header: "Transaction ID", accessor: "id" },
            { Header: "Customer", accessor: "customer" },
            { Header: "Created time", accessor: "createdAt" },
            { Header: "Actions", accessor: "actions" },
          ],
          rows: getRows(),
        }}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageCount={_.get(data, "pagination.totalPages", 0)}
        currentPage={currentPage}
        manualPagination={true}
        rowCount={_.get(data, "pagination.totalDocs", 0)}
        isLoading={isLoading}
        isDownloadable={true}
        dataToDownload={dataToDownload}
        isServerDownload={true}
        onDownloadClick={handleDownloadClick}
        filename="Transaction_Reporting.csv"
      />
      <NotificationModal
        transaction={currentSelectedTransaction}
        onClose={handleNotificationClose}
        isOpen={notificationModalOpen}
      />
      <RefundModal
        transaction={currentSelectedTransaction}
        onClose={setRefundedModalClose}
        isOpen={refundedModalOpen}
      />
    </MainLayout>
  );
};

export default MerchantReporting;
