import { useMutation, useQuery, useQueryClient } from "react-query";
import { getQueryParam, getTemplatePath } from "../../../utils/string";
import request from "../../../utils/request";

import { toastSuccess } from "../../../utils/toast";
import { getToken } from "../../../utils/cache";
import jwtDecode from "jwt-decode";

const GET_MANAGE_USERS = "/users";
const CREATE_USER = "/users";
const GET_ONE_USER = "/users/:id";
const DELETE_USER = "/users/:id";

const RESET_USER_PASSWORD = "/auth/forgot-password";

const getManageUsers = async ({ role, brandId }) => {
  const params = getQueryParam({
    role,
    brandId: brandId === "All" ? "" : brandId,
  });
  return request.get(`${GET_MANAGE_USERS}?${params}`);
};

const createManageUser = (payload) => {
  return request.post(CREATE_USER, payload);
};

const deleteManageUser = ({ id }) => {
  return request.delete(getTemplatePath(DELETE_USER, { id }));
};
const getOneUser = (id) => {
  return request.get(getTemplatePath(GET_ONE_USER, { id }));
};
const resetUserPassword = (payload) =>
  request.post(RESET_USER_PASSWORD, payload);

export const useGetManageUsers = (role) => {
  const adminInfo = getToken() && jwtDecode(getToken());
  if (adminInfo?.role !== "ACCOUNT")
    return useQuery([GET_MANAGE_USERS], () => getManageUsers(role));
};
export const useCreateManageUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createManageUser, {
    onSuccess: () => {
      toastSuccess({ description: "Create user has been success" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

export const useDeleteManageUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteManageUser, {
    onSuccess: () => {
      toastSuccess({ description: "User deleted successfully" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

export const useGetOneUser = (id) => {
  return useQuery([GET_ONE_USER], () => getOneUser(id));
};

export const useResetUserPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(resetUserPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};
