import { useNavigate } from "react-router-dom";
import React from "react";

import CreateBrand from "./CreateBrand";
import { useGetBrands } from "../pay-by-link/hooks";
import _ from "lodash";
import SoftButton from "../../components/SoftButton";
import SoftBox from "../../components/SoftBox";
import DataTable from "../../examples/Tables/DataTable";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";

const Brands = () => {
  const navigate = useNavigate();

  const { data } = useGetBrands();

  const getRows = () => {
    return _.map(data, (item) => ({
      id: item._id,
      name: item.name,
      ipWhitelists: item.ipWhitelists,
      actions: (
        <SoftButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => navigate(`/brands/${item._id}`)}
        >
          Edit
        </SoftButton>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />{" "}
      <SoftBox>
        <SoftBox display="flex" justifyContent="flex-end">
          <CreateBrand />
        </SoftBox>
        <SoftBox mt={2}>
          <DataTable
            table={{
              columns: [
                { Header: "name", accessor: "name" },
                { Header: "id", accessor: "id" },
                { Header: "actions", accessor: "actions" },
              ],
              rows: getRows(),
            }}
          />
        </SoftBox>
      </SoftBox>
    </MainLayout>
  );
};

export default Brands;
