import React, { useEffect, useState } from "react";
import _ from "lodash";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import { getToken } from "../../utils/cache";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useGetBrands } from "../pay-by-link/hooks";
import SoftSelect from "../../components/SoftSelect";
import SoftTypography from "../../components/SoftTypography";
import ManageUsers from "../settings/components/ManageUsers";
import jwtDecode from "jwt-decode";

const MerchantAccounts = () => {
  const [currentBrand, setCurrentBrand] = useState({});
  const navigate = useNavigate();
  const adminInfo = getToken() && jwtDecode(getToken());

  useEffect(() => {
    if (!getToken()) navigate("/sign-in");
    if (adminInfo?.role === "ACCOUNT") navigate("/pay-by-link");
    setCurrentBrand({ value: "All", label: "All" });
  }, []);
  const { data: brands } = useGetBrands();

  const brandOptions = [
    { value: "All", label: "All" },
    ..._.map(brands, (item) => ({
      value: item._id,
      label: item.name,
    })),
  ];

  const handleChange = (brand) => {
    setCurrentBrand(brand);
  };

  return (
    <MainLayout>
      <MainNavbar />
      {brands && (
        <Grid item xs={9} sx={{ mt: 3 }}>
          <SoftTypography variant="h6" fontWeight="medium">
            Brand
          </SoftTypography>
          <Grid sx={{ background: "white!important", borderRadius: "0.5rem" }}>
            <SoftSelect
              defaultValue={{ value: "All", label: "All" }}
              options={brandOptions}
              onChange={handleChange}
              sx={{ width: "100%!important" }}
            />
          </Grid>
        </Grid>
      )}
      <ManageUsers role={"ACCOUNT"} brand={currentBrand?.value} />
    </MainLayout>
  );
};

export default MerchantAccounts;
