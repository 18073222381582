import { useQuery } from "react-query";
import request from "../../utils/request";

const GET_TRANSACTION_DETAIL = "transactions/";

// define the function to fetch transaction detail by id
const getTransaction = (id) => request.get(`${GET_TRANSACTION_DETAIL}${id}`);

// define the custom hook to get transaction detail
export const useGetTransactionDetail = (id) => {
  return useQuery([GET_TRANSACTION_DETAIL, id], () => getTransaction(id), {
    enabled: !!id,
  });
};
