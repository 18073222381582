import { Chip } from "@mui/material";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { StatusColorEnum } from "../../constans/status";

// eslint-disable-next-line react/display-name
const StatusChip = forwardRef(({ label, ...rest }, ref) => {
  const color = StatusColorEnum[label];

  return <Chip label={label} {...rest} ref={ref} color={color} />;
});
// Setting default values for the props of SoftPagination
StatusChip.defaultProps = {
  label: "",
};

// Typechecking props for the SoftPagination
StatusChip.propTypes = {
  label: PropTypes.string,
};
export default StatusChip;
