import PropTypes from "prop-types";
import SoftBox from "../SoftBox";
import React from "react";

const WrapperItem = ({ index, children, actionsLength }) => {
  const mx = actionsLength === 2 ? 1 : 2;
  if ((index !== 0 && index !== actionsLength - 1) || actionsLength <= 2) {
    return <SoftBox mx={mx}>{children}</SoftBox>;
  } else {
    return <div>{children}</div>;
  }
};

// Setting default values for the props of WrapperItem
WrapperItem.defaultProps = {
  actionsLength: 0,

  children: false,
};

// Typechecking props of the WrapperItem
WrapperItem.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  actionsLength: PropTypes.number.isRequired,
};

export default WrapperItem;
