import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import _ from "lodash";

import NotificationsIcon from "@mui/icons-material/Notifications";

import { useSendNotification } from "../hooks";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import React from "react";

const NotificationModal = ({ isOpen, onClose, transaction }) => {
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  // //hooks
  const { isLoading, mutate } = useSendNotification();
  // // create a function that handles the form submission
  const onSubmit = () => {
    mutate(
      { transactionId: _.get(transaction, "id") },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Send Notification
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox display="flex" alignItems="center">
                      <NotificationsIcon
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          color: (theme) => theme.palette.secondary.main,
                        }}
                      />
                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Notification
                        </SoftTypography>
                        <SoftTypography
                          variant="button"
                          color="text"
                          fontWeight="regular"
                        >
                          Are you sure you want to send notification for
                          transaction {_.get(transaction, "id")}?
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Send
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Setting default values for the props of NotificationModal
NotificationModal.defaultProps = {
  isOpen: false,
};

// Typechecking props for the NotificationModal
NotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};

export default NotificationModal;
