import MainNavbar from "../../examples/Navbars/MainNavbar";
import React from "react";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import { getToken } from "../../utils/cache";
import ManageUsers from "./components/ManageUsers";

const SettingsPage = () => {
  return getToken() ? (
    <MainLayout>
      <MainNavbar />
      <ManageUsers role={"ADMIN"} />
    </MainLayout>
  ) : (
    (window.location.href = "/sign-in")
  );
};

export default SettingsPage;
