import { useForm } from "react-hook-form";
import { useForgotPassword } from "./useAuth";
import React from "react";
import { toastSuccess } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import PageLayout from "../../examples/LayoutContainers/PageLayout";

function ForgotPassword() {
  const { register, handleSubmit } = useForm({ defaultValues: { email: "" } });
  const navigate = useNavigate();

  const { mutate, isLoading } = useForgotPassword();

  const handleSubmitForm = (values) => {
    mutate(values, {
      onSuccess: () => {
        navigate(-1);
        toastSuccess({
          description: `Password reset link has been sent to ${values.email}, check email! 🙂`,
        });
      },
    });
  };

  return (
    <PageLayout background="white">
      {" "}
      <SoftBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
        component="form"
        role="form"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <SoftBox width={"350px"}>
          <SoftBox mb={1}>
            <SoftTypography variant="h4" fontWeight="bold">
              Forgot your password?
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="body2" fontWeight="regular" color="text">
            Please enter the email address associated with your account and We
            will email you a link to reset your password.
          </SoftTypography>
          <SoftBox my={2}>
            <SoftInput
              type="email"
              placeholder="Email"
              size="large"
              {...register("email")}
            />
          </SoftBox>

          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              disabled={isLoading}
            >
              reset password
            </SoftButton>
          </SoftBox>
          <SoftBox mt={2} mb={1}>
            <SoftButton
              variant="gradient"
              color="secondary"
              size="large"
              fullWidth
              onClick={() => {
                navigate(-1);
              }}
            >
              back
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </PageLayout>
  );
}

export default ForgotPassword;
