export const sanitizeRequest = ({
  zipCode,
  welcomeMessage,
  thankYouMessage,
  streetNumber,
  street,
  state,
  phone,
  name,
  merchantSpecificText,
  merchantReference,
  merchantDomain,
  expiresIn,
  enabled,
  email,
  customerCountry,
  currency,
  city,
  amount,
}) => {
  return expiresIn
    ? {
        enabled,
        payload: {
          currency,
          amount,
          customer: {
            name,
            email,
            phone,
            address: {
              street,
              streetNumber,
              zipCode,
              city,
              state,
              country: customerCountry,
            },
          },
          merchantReference,
          merchantDomain,
        },
        welcomeMessage,
        thankYouMessage,
        merchantSpecificText,
        expiresIn: Number(expiresIn) === 0 ? null : Number(expiresIn),
      }
    : {
        enabled,
        payload: {
          currency,
          amount,
          customer: {
            name,
            email,
            phone,
            address: {
              street,
              streetNumber,
              zipCode,
              city,
              state,
              country: customerCountry,
            },
          },
          merchantReference,
          merchantDomain,
        },
        welcomeMessage,
        thankYouMessage,
        merchantSpecificText,
      };
};
