import React, { useEffect } from "react";
import _ from "lodash";

import { useGetManageUsers } from "../users/hooks";
import DataTable from "../../../examples/Tables/DataTable";
import AddManageUserModal from "./AddManageUserModal";
import DeleteManageUser from "./DeleteManageUser";
import SoftTypography from "../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

const ManageUsers = ({ role, brand = null }) => {
  const { data, refetch } = useGetManageUsers({ role: role, brandId: brand });
  useEffect(() => {
    refetch();
  }, [brand]);
  const getRows = () => {
    return _.map(data, (item) => ({
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      role: item.role,
      actions: (
        <>
          <DeleteManageUser merchant={item} brand={brand} />
        </>
      ),
    }));
  };

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <SoftTypography variant="h5" fontWeight="medium">
            {role === "ADMIN" ? "Manage Users" : "Manage Accounts"}
          </SoftTypography>
        </Grid>
        <Grid item>
          <AddManageUserModal brand={brand} role={role} />
        </Grid>
      </Grid>

      <DataTable
        table={{
          columns: [
            { Header: "Name", accessor: "name" },
            { Header: "Surname", accessor: "surname" },
            { Header: "Email Address", accessor: "email" },
            { Header: "Role", accessor: "role" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows() || [],
        }}
      />
    </>
  );
};

export default ManageUsers;

ManageUsers.propTypes = {
  role: PropTypes.string,
  brand: PropTypes.string,
};
