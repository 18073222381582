import _ from "lodash";
import axios from "axios";
import { toastError } from "./toast";
import { getToken } from "./cache";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30 * 1000,
});

// request interceptor
request.interceptors.request.use((axiosConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${getToken()}`,
  };

  return axiosConfig;
});

request.interceptors.response.use(
  (response) => _.get(response, "data.message") || _.get(response, "data"),
  (error) => {
    const config = _.get(error, "response.config");
    const data = _.get(error, "response.data");

    const statusCode = _.get(data, "statusCode");
    const method = _.get(config, "method");
    const message =
      typeof data?.message === "string"
        ? data?.message
        : data?.message?.join(" ");
    if (statusCode === 401 && !["post"].includes(method)) {
      window.location.href = "/sign-in";
    }

    if (["post", "delete", "put"].includes(method)) {
      toastError({
        description: typeof data[0] === "string" ? data[0] : message,
      });
    }
    return Promise.reject(error);
  }
);

export default request;
