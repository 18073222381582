import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "../SoftBox";
import SoftAvatar from "../SoftAvatar";
import SoftTypography from "../SoftTypography";

import { SelectInputField } from "../FormField";

import { countriesFlags } from "../../constans/countriesFlags";
import { countries } from "../../constans/countries";

const CountrySelect = ({ name, label, required, ...rest }) => {
  const countriesOptions = countries.slice(1, -1).map((item) => {
    const countryWithFlag = _.find(countriesFlags, { code: item.code });
    const flag = _.get(countryWithFlag, "image", "");
    return {
      value: item.code,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size="sm" alt="title" />
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.label}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });
  return (
    <SelectInputField
      {...rest}
      name={name}
      label={label}
      options={countriesOptions}
      required={required}
    />
  );
};

CountrySelect.defaultValues = {
  required: false,
  label: "",
};
CountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default CountrySelect;
