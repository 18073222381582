import React from "react";
import { useForm } from "react-hook-form";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BrandForm from "./BrandForm";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "./schemas/brand";
import SoftButton from "../../components/SoftButton";
import FormProvider from "../../components/FormProviders";
import SoftBox from "../../components/SoftBox";
import { useCreateBrand } from "./hooks";

const CreateBrand = () => {
  const [open, setOpen] = React.useState(false);

  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;
  const { mutate, isLoading } = useCreateBrand();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitForm = (values) => {
    mutate(values, {
      onSuccess: () => {
        handleClose();
        reset();
      },
    });
  };

  return (
    <>
      <SoftButton
        variant="gradient"
        color="info"
        onClick={handleClickOpen}
        disabled={isLoading}
      >
        New brand
      </SoftButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <DialogTitle id="alert-dialog-title">Create new brand</DialogTitle>
          <DialogContent>
            <BrandForm />
            <SoftBox display="flex" justifyContent="center" gap={2} mt={2}>
              <SoftButton onClick={handleClose}>Cancel</SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
              >
                Create Brand
              </SoftButton>
            </SoftBox>
          </DialogContent>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default CreateBrand;
